import { Col, Row } from "react-bootstrap";
import "../styles.scss";
import medical from "../../assets/images/medicalcare.jpg";
import cozy from "../../assets/images/cozyy.png";
import memory from "../../assets/images/memory2.png";
import care from "../../assets/images/companion.png";
import { IoArrowForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const Fade = require("react-reveal/Fade");

const Section2 = () => {
  const navigate = useNavigate();
  const cards = [
    { id: 1, title: "24/7", description: "Medical Care", image: medical },
    { id: 2, title: "Safe and", description: "Cozy Rooms", image: cozy },
    { id: 3, title: "Memory", description: "Support Services", image: memory },
    { id: 4, title: "Care and", description: "Companionship", image: care },
  ];
  const cards2 = [
    {
      id: 1,
      title: "Schedule an ",
      head: "Assessment",
      description: "You can take the first step towards personalized care.",
      position: "left",
    },
    {
      id: 2,
      title: "Meet Our ",
      head: "Caregivers",
      description: "Get to know our compassionate and professional caregivers.",
      position: "right",
    },
  ];
  return (
    <div className="sections_box">
      <div className=" text-center">
        <div className="section2_text1 Regular_font Secondary_color">
          How We Meet Your Needs
        </div>
        <div className="section2_text2 Blue_color Bold_font">
          Service We Offer
        </div>
        <div className="section2_text3  Light_font mt-4 Secondary_color">
          Explore the wide variety of services we provide dedicated to you.
        </div>
      </div>
      <br />
      <br />
      <Row className="g-4 g-lg-4">
        {cards.slice(0, 2).map((item: any) => (
          <Col md={6} xs={12}>
            <Fade bottom>
              <div className="section2_card1">
                <div>
                  <img
                    src={item?.image}
                    className="section2_images"
                    alt="image1"
                  />
                </div>

                <div className="section_box">
                  <div className="section_box1 ">
                    <div className="section2_text3c Secondary_color Light_font">
                      {item?.title}
                    </div>
                    <div className="section2_text4 Secondary_color Regular_font">
                      {item?.description}
                    </div>
                  </div>
                  <div className="blueArrow">
                    <IoArrowForward color="white" size={20} />
                  </div>{" "}
                </div>
              </div>
            </Fade>
          </Col>
        ))}

        <Col xl={3} className="d-none d-xl-block" />
        {cards2.map((item: any, index: number) => (
          <Col md={6} xl={3} xs={12}>
            <Fade bottom>
              <div className="d-flex align-items-center my-md-4">
                <div
                  className={`blue_round ${index === 0 ? "left" : "right"}`}
                />
                <div
                  className="section2_card2"
                  onClick={() => navigate("/contact-us")}
                >
                  <div className="section2_card_box1">
                    <div>
                      <div className="section2_card_text1 Light_font text-white">
                        {item?.title}
                      </div>
                      <div className="section2_text4 Regular_font text-white">
                        {item?.head}
                      </div>
                    </div>
                    <div className="blueArrow">
                      <IoArrowForward color="white" size={20} />
                    </div>{" "}
                  </div>

                  <div className="section2_card_text1 Light_font text-white">
                    {item?.description}
                  </div>
                </div>
              </div>
            </Fade>
          </Col>
        ))}
        <Col xl={3} className="d-none d-xl-block" />
        {cards.slice(2, 4).map((item: any) => (
          <Col md={6} xs={12}>
            <Fade bottom>
              <div className="section2_card1">
                <div>
                  <img
                    src={item?.image}
                    className="section2_images"
                    alt="image1"
                  />
                </div>

                <div className=" section_box ">
                  <div className="section_box1 ">
                    <div className="section2_text3 Secondary_color Light_font">
                      {item?.title}
                    </div>
                    <div className="section2_text4 Secondary_color Regular_font">
                      {item?.description}
                    </div>
                  </div>
                  <div className="blueArrow">
                    <IoArrowForward color="white" size={20} />
                  </div>{" "}
                </div>
              </div>
            </Fade>
          </Col>
        ))}
      </Row>
      <br />
      <br />
    </div>
  );
};

export default Section2;
