import { Col, Container, Row } from "react-bootstrap";
import { IoArrowForward } from "react-icons/io5";
import LOGO from "../../assets/images/SD-LOGO.svg";
const Fade = require("react-reveal/Fade");

const Section3: any = () => {
  const cards = [
    {
      id: 1,
      title: "We are here for you ",
      head: "About us",
      description:
        "Our mission is to provide special home care to our clients...",
      delay: 0,
    },
    {
      id: 2,
      title: "Insurance",
      head: " Accepted",
      description:
        "Ease your financial matters with our insurance-friendly policies.",
      delay: 200,
    },
    {
      id: 3,
      title: "Send Your ",
      head: "Referrals",
      description:
        "Help us extend our circle of care by referring somone to us",
      delay: 500,
    },
  ];
  return (
    <div className="sections_box">
      <Container>
        <Row
          className="px-0"
          style={{
            padding: 0,
          }}
        >
          <Col md={6} xs={12}>
            <Fade bottom>
              <div style={{ width: "95%" }} className="h-100 ">
                <div
                  className="section2_text1 Regular_font mb-3"
                  style={{ color: "#193076" }}
                >
                  Welcome to
                </div>
                <div className="py-1 py-md-3">
                  <img src={LOGO} alt="logo" className="section3_logo" />
                </div>
                <br />

                <div className="section3_para Regular_font Secondary_color mb-3 mb-md-0">
                  We acknowledge the universal need for quality home care across
                  all age groups. Whether dealing with recovery, chronic
                  conditions, or the challenges of everyday activities, everyone
                  deserves support within their own homes.
                </div>
                <br className="d-none d-md-block" />
                <div className="section3_para Regular_font Secondary_color mb-3 mb-md-0">
                  As your reliable provider of home care,{" "}
                  <strong>Silver Dove Health Services</strong>, delivers
                  personalized services from dedicated caregivers. We extend our
                  expertise, always placing your needs at the forefront and we
                  aim to offer peace of mind by ensuring that every elder
                  receives the respect, attention, and professional care they
                  deserve.
                </div>
              </div>
            </Fade>
          </Col>
          <Col md={6} xs={12}>
            <Fade bottom>
              <div className="section3_box2 h-100 ">
                <img
                  src={require("../../assets/images/section3-image.png")}
                  alt="image1"
                  style={{
                    width: "100%",

                    objectFit: "cover",
                  }}
                />
              </div>
            </Fade>
            <br />
            <br />
          </Col>
        </Row>

        <Row>
          {cards.map((item: any) => (
            <Col xs={12} md={4} className="my-3 ">
              <Fade bottom delay={item?.delay}>
                <div className="section2_card3 ">
                  <div className="section2_card_box1">
                    <div>
                      <div className="section2_card_text2 Light_font">
                        {item?.title}
                      </div>
                      <div className="section2_text4 Regular_font">
                        {item?.head}
                      </div>
                    </div>
                    <div className="blueArrow">
                      <IoArrowForward color="white" size={20} />
                    </div>
                  </div>
                  <div className="section2_card_text1 Light_font pt-1">
                    {item?.description}
                  </div>
                </div>
              </Fade>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Section3;
