import { useEffect } from "react";
import Header from "../components/header";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/footer";
import img1 from "../assets/images/about-img1.png";
import img2 from "../assets/images/about-img2.png";
import img3 from "../assets/images/about-img3.png";
import { Button } from "antd";
const Fade = require("react-reveal/Fade");

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Header />
      <Container className="common_cover">
        <Row>
          <Col md={6}>
            <Fade bottom>
              <div className="h-100 d-flex flex-column justify-content-center ">
                <div className="page-common-txt1 Blue_color Bold_font mb-2">
                  Discover the Silver Dove’s Difference:
                </div>
                <div className="section1_text1 Blue_color Bold_font">
                  Unparalleled Home <br className="d-none d-md-block" /> Care
                  Grounded in <br className="d-none d-md-block" /> Compassion
                  and
                  <br /> Expertise
                </div>
              </div>
            </Fade>
          </Col>
          <Col md={6} className="mt-4 mt-md-0">
            <Fade bottom>
              <img className="w-100" src={img1} alt="" />
            </Fade>
          </Col>
          <Col md={6} className="mt-2 mt-md-5 pt-3">
            <Fade bottom>
              <h5 className="Medium_font Secondary_color">
                Unparalleled home care
              </h5>
              <p className="Light_font Secondary_color mt-3">
                In today’s society, home care is more than mere convenience;
                it’s a fundamental pillar of healthcare with a profound impact
                on the lives of those it serves. This is especially true at a
                time when the emphasis on the comfort and familiarity of home as
                a factor in health and wellness has never been greater. By
                providing essential health services within the individual’s own
                living space, home care helps bridge the gap between clinical
                health care and personal well-being, catering not just to the
                physical but also the emotional needs of the individual.
              </p>
            </Fade>
          </Col>
          <Col md={6} className="mt-0 mt-md-5 pt-2 pt-md-3">
            <Fade bottom>
              <h5 className="Medium_font Secondary_color">
                Why Choose Elders Silver Dove Health Services?
              </h5>
              <p className="Light_font Secondary_color mt-3">
                Choosing Silver Dove Health Services means entrusting your care
                to dedicated professionals who prioritize your unique needs. We
                offer personalized care plans, a team of experienced and
                compassionate caregivers, and a comprehensive range of services
                to cater to diverse needs. We accept insurance, easing financial
                concerns, and we continually strive to improve and innovate in
                our service delivery. With us, you’re not just getting a
                provider – you’re gaining a partner in health and an extended
                family that cares deeply about your well-being.
              </p>
            </Fade>
          </Col>
        </Row>
      </Container>
      <div className="about_bgImg">
        <Container className="d-flex flex-column justify-content-center h-100">
          <Fade bottom>
            <div
              style={{ letterSpacing: 2 }}
              className="text-white Regular_font"
            >
              We're Here When You Need Us
            </div>
            <h1
              style={{ letterSpacing: 0.2 }}
              className="SemiBold_font text-white mt-2 mb-2"
            >
              Our Mission Statement
            </h1>
            <p className="text-white Regular_font mb-3">
              At Silver Dove Health Service, our mission is to
              <br className="d-none d-sm-block" /> provide comprehensive,
              compassionate, and <br className="d-none d-sm-block" />
              personalized home care to our clients…
            </p>
            <Button
              className="common_button Regular_font"
              style={{ backgroundColor: "#1A75BB" }}
            >
              About Us
            </Button>
          </Fade>
        </Container>
      </div>
      <div style={{ backgroundColor: "#F6FCFF", display: "flex" }}>
        <Container className="my-4 my-md-5 py-0 py-md-5">
          <Fade bottom>
            <div className="section1_text1 Blue_color Bold_font text-center mb-4 mb-md-5">
              Vision & Mission
            </div>
          </Fade>
          <Row className="gx-0 gx-sm-5">
            <Col md={6}>
              <Fade bottom>
                <img className="w-100" src={img2} alt="" />
                <h4 className="Blue_color Bold_font mt-3 mt-md-4">
                  Our Vision
                </h4>
                <p className="Light_font Secondary_color mt-2">
                  Our vision is to be the leading choice for home care in Texas,
                  renowned for our patient-centered approach and commitment to
                  exceptional service. We aim to set the standard for home care
                  excellence, fostering a supportive environment that encourages
                  growth, empowerment and enhanced well-being for our clients.
                </p>
              </Fade>
            </Col>
            <Col md={6} className="mt-3 mt-md-0">
              <Fade bottom>
                <img className="w-100" src={img3} alt="" />
                <h4 className="Blue_color Bold_font mt-3 mt-md-4">
                  Our Mission
                </h4>
                <p className="Light_font Secondary_color mt-2">
                  Our mission is to be the premier provider of senior living
                  services and old age care in India, and we aim to transform
                  the industry through innovative care, employee development and
                  resident lifestyle enhancement. Our values, including
                  integrity, respect, and compassion, form the foundation of our
                  interactions with both our elderly residents and dedicated
                  employees.
                </p>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
