import { Col, Container, Row } from "react-bootstrap";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { RiFacebookFill } from "react-icons/ri";
import logo from "../assets/images/SD-LOGO-LIGHT.svg";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer ">
      <Container>
        <Row>
          <Col md={6}>
            <div>
              <img src={logo} className="footerLogo" alt="logo" />
              <div className="section3_para Regular_font mt-4 text-white">
                <div className="mt-3">
                  <a
                    target="_blank"
                    style={{ textDecoration: "none", color: "inherit" }}
                    rel="noreferrer"
                    href="https://www.google.com/maps/place/5655+Walnut+Mill+Ln,+Hiram,+GA+30141,+USA/@33.8677074,-84.7152375,707m/data=!3m2!1e3!4b1!4m6!3m5!1s0x88f53b3679e660b1:0x7bd7de34831adcc6!8m2!3d33.8677074!4d-84.7126626!16s%2Fg%2F11c1d66c28?entry=ttu&g_ep=EgoyMDI0MDkxNi4wIKXMDSoASAFQAw%3D%3D"
                  >
                    Address: 5655 walnut mill lane, Powder Springs. GA. 30127.
                  </a>
                </div>
                <div className="mt-1">
                  {" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="tel:+14049935463"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Phone: (404) 993-5463
                  </a>
                </div>
                <div className="mt-1">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:Blessing@silverdovehealthservices.com"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Email: Blessing@silverdovehealthservices.com
                  </a>{" "}
                </div>
                <div className="mt-1">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://silverdove.bairuhatech.com/"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Web: www.silverdovehealthservices.com
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="row mt-3 mt-md-0">
                <div className="col-md-4 col-0" />
                <div className="col-12 col-md-4 d-flex justify-content-md-end justify-content-start ">
                  <div className="d-flex  flex-column ">
                    <ul className="list-unstyled d-flex flex-column gap-2">
                      <li className="label text-white">Insurance</li>
                      <li className="label text-white">Refferals</li>
                      <li className="label text-white ">Our Team</li>
                      <li
                        className="label text-white "
                        onClick={() => navigate("/careers")}
                      >
                        Career Plans
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-md-end justify-content-start">
                  <div className="d-flex flex-column  ">
                    <ul className="list-unstyled d-flex flex-column gap-2">
                      <li
                        className="label text-white"
                        onClick={() => navigate("/about-us")}
                      >
                        About Us
                      </li>
                      <li
                        className="label text-white"
                        onClick={() => navigate("/services")}
                      >
                        Services
                      </li>
                      <li
                        className="label text-white"
                        onClick={() => navigate("/careers")}
                      >
                        Careers
                      </li>
                      <li
                        className="label text-white"
                        onClick={() => navigate("/contact-us")}
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-3 justify-content-md-end justify-content-start">
                <div className="social_icons">
                  <FaInstagram size={25} color="white" />
                </div>
                <div className="social_icons">
                  <RiFacebookFill size={25} color="white" />
                </div>
                <div className="social_icons">
                  <FaXTwitter size={25} color="white" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
