import React from "react";
import { Container } from "react-bootstrap";
import Header from "../components/header";
import Footer from "../components/footer";
import Section1 from "./components/section1";
import Section2 from "./components/section2";
import Section3 from "./components/section3";
import Section4 from "./components/section4";
import Section5 from "./components/section5";

const Home = () => {
  return (
    <>
      <Header />
      <Container>
        <Section1 />
        <Section2 />
      </Container>
      <Section5 />
      <Section3 />
      <Section4 />
      <Footer />
    </>
  );
};

export default Home;
