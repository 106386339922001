import { Col, Container, Row } from "react-bootstrap";
import { TbBulb } from "react-icons/tb";
import { LiaAngellist } from "react-icons/lia";
import { FaPeopleRoof } from "react-icons/fa6";
import { VscGraphLine } from "react-icons/vsc";
import { PiHandshake } from "react-icons/pi";
import first from "../../assets/images/1.svg";
import second from "../../assets/images/2.svg";
import third from "../../assets/images/3.svg";
import fourth from "../../assets/images/4.svg";
import fifth from "../../assets/images/5.svg";
import sixth from "../../assets/images/6.svg";
const Fade = require("react-reveal/Fade");
const Section5 = () => {
  const cards = [
    {
      head: (
        <div>
          Understand
          <br />
          ing Needs
        </div>
      ),
      icon: first,
      delay: 0,
      color: "red",
    },
    {
      head: (
        <div>
          Tailoring A<br /> Customized Care Plan
        </div>
      ),
      icon: second,
      color: "green",
    },
    {
      head: (
        <div>
          Reliable Care
          <br /> Team
        </div>
      ),
      icon: third,
      color: "violet",
    },
    {
      head: (
        <div>
          Complete
          <br /> Personal Care
        </div>
      ),
      icon: fourth,
    },
    {
      head: (
        <div>
          Helping Constant
          <br /> Improvement
        </div>
      ),
      icon: fifth,
    },
    {
      head: (
        <div>
          Client Centric
          <br /> Approach
        </div>
      ),
      icon: sixth,
    },
  ];
  return (
    <div style={{ backgroundColor: "#4b9fdf", padding: "100px 0px 120px 0px" }}>
      <Container>
        <div className="section2_text2 Blue_color Bold_font text-center text-white">
          What we assure
        </div>

        <Row style={{ padding: 0 }} className="px-0 ">
          {cards.slice(0, 3).map((item: any, index) => (
            <Col md={4}>
              <Fade bottom>
                <div className="section5_box">
                  <img src={item?.icon} />
                  <div className="section5_card_head SemiBold_font mt-2 Secondary_color">
                    {item?.head}
                  </div>
                </div>
              </Fade>
            </Col>
          ))}

          {cards.slice(3, 6).map((item: any, index) => (
            <Col md={4}>
              <Fade bottom>
                <div className="section5_box">
                  <img src={item?.icon} />
                  <div className="section5_card_head Secondary_color SemiBold_font">
                    {item?.head}
                  </div>
                </div>
              </Fade>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Section5;
