import { useState } from "react";
import { Col, Container } from "react-bootstrap";
import "./styles.scss";
import { HiMenu, HiOutlineMailOpen } from "react-icons/hi";
import logo from "../assets/images/SD-LOGO.svg";
import { IoCallOutline } from "react-icons/io5";
import CustomDrawer from "./drawer";
import { useNavigate } from "react-router-dom";
const Fade = require("react-reveal/Fade");

const Header = () => {
  const naviagte = useNavigate();
  const [open, setOpen] = useState(false);
  const options = [
    { id: 1, name: "Home", path: "/" },
    { id: 2, name: "About Us", path: "/about-us" },
    { id: 3, name: "Services", path: "/services" },
    { id: 4, name: "Careers", path: "/careers" },
    { id: 5, name: "Resources", path: "/resources" },
    { id: 6, name: "Contact Us", path: "/contact-us" },
  ];

  return (
    <>
      <div className="header1_Box Primary_color">
        <Container>
          <div className="header1_Box1 Medium_font">
            <span>
              <a
                href="tel:+14049935463"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <IoCallOutline size={20} /> &nbsp; Need assistance? Call us
                today: (404) 993-5463
              </a>
            </span>
            <span>
              <a
                href="mailto:Blessing@silverdovehealthservices.com"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <HiOutlineMailOpen size={20} /> &nbsp; Email Address:
                Blessing@silverdovehealthservices.com
              </a>
            </span>
          </div>
        </Container>
      </div>

      <div className="header2_Box">
        <Container>
          <div className="header2_Box1">
            <Col md={4}>
              <Fade top>
                <div className="logo_box">
                  <img src={logo} alt="logo" onClick={() => naviagte("/")} />
                </div>
              </Fade>
            </Col>
            <Col md={2} />
            <Col md={6}>
              <ul className="header2_Box2 Secondary_color Regular_font">
                {options.map((item: any) => (
                  <li
                    onClick={() => naviagte(item?.path)}
                    style={{ cursor: "pointer" }}
                  >
                    {item?.name}
                  </li>
                ))}
              </ul>
            </Col>

            <div
              className="d-block d-lg-none "
              onClick={() => setOpen(true)}
              style={{ cursor: "pointer" }}
            >
              <HiMenu size={30} color="#1a75bb" />
            </div>
          </div>
        </Container>

        {open && (
          <CustomDrawer
            data={options}
            open={open}
            close={() => setOpen(false)}
          />
        )}
      </div>
    </>
  );
};

export default Header;
