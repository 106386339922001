import { Drawer } from "antd";
import "./footer";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/SD-LOGO.svg";

const Fade = require("react-reveal/Fade");
const CustomDrawer = (props: any) => {
  const naviagte = useNavigate();
  return (
    <Drawer
      open={props?.open}
      onClose={props?.close}
      width={250}
      footer={false}
      closeIcon={false}
    >
      {" "}
      <div className="drawer">
        <div>
          <img
            src={logo}
            alt="logo"
            onClick={() => naviagte("/")}
            style={{ width: "200px", height: "30px", marginLeft: "-34px" }}
          />
        </div>
        <br />
        <ul className="drawer_Box">
          {props?.data?.map((item: any) => (
            <>
              <Fade top duration={2000}>
                <li
                  onClick={() => naviagte(item.path)}
                  className="drawer_Box_list Primary_color "
                >
                  {item?.name}
                </li>
              </Fade>
            </>
          ))}
        </ul>
      </div>
    </Drawer>
  );
};

export default CustomDrawer;
