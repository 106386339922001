import { useEffect } from "react";
import Header from "../components/header";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Card } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/footer";
import img1 from "../assets/images/service-img1.png";
import img2 from "../assets/images/service-img2.png";
import img3 from "../assets/images/service-img3.png";
import img4 from "../assets/images/service-img4.png";
import img5 from "../assets/images/service-img5.png";
import { IoArrowForward } from "react-icons/io5";
const Fade = require("react-reveal/Fade");

const Services = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const cards = [
    {
      id: 1,
      title: "Accomodation",
      description:
        "All our accommodation rooms are well-furnished and come with plenty of in-room amenities",
      image: img2,
    },
    {
      id: 2,
      title: "Interaction Center",
      description:
        "We try and initiate a better understanding of ageing from a social prespective",
      image: img3,
    },
    {
      id: 3,
      title: "Medical Checkup",
      description:
        "We conduct routine medical-checkup and provide medication to all our elderly patients",
      image: img4,
    },
    {
      id: 4,
      title: "Nursing & Personal Care",
      description:
        "We provide self-contained rooms and 24 hour nursing & personal care for aged person",
      image: img5,
    },
  ];
  const services = [
    {
      img: require("../assets/images/emergency.png"),
      title: "Emergency Support",
      details: [
        "24 X 7 Helpline To Coordinate Emergency Support",
        "Emergency Response Protocol Setup For The Family",
        "Hospitalization Management",
        "Medical Emergency Fund Management",
        "Real-Time Coordination And Regular Updates To Family",
      ],
    },
    {
      img: require("../assets/images/mobility.png"),
      title: "Mobility & Travel",
      details: [
        "Transport & Travel Planning And Arrangement Assistance",
        "Assist In Packing, Moving Or Relocating",
        "Arrange Pick-Up And Drop For Local Travel",
        "Customized And Group Tours For Seniors",
        "Companion For Local & Domestic/International Drops",
      ],
    },
    {
      img: require("../assets/images/social.png"),
      title: "Social Engagement",
      details: [
        "Organize Leisure & Recreation, Walks, Reading, And Indoor Games",
        "Accompanied Social Visits",
        "Opportunities For Learning New Skills And Hobbies",
        "Organize Mental & Physical Wellness Activities",
        "Video, Online & In-Person Community Meet-Ups",
        "Samarth Accompanied, Customized, And Group Tours For Seniors",
        "Opportunities To Participate In Professional Work",
      ],
    },
    {
      img: require("../assets/images/health.png"),
      title: "Health & Hospitalization",
      details: [
        "Medical Care And Coordination Helpline",
        "Accompanied Visits For Medical Appointments",
        "Schedule & Coordinate Diagnostic Tests, Doctor Appointments",
        "Second Opinion From Specialists",
        "Surgery/Procedure Planning",
        "GP Review & Health Profiling",
        "Medical Claims Assistance",
      ],
    },
  ];
  return (
    <div>
      <Header />
      <Container>
        <div className="common_cover">
          <Row className="md-0 mb-md-5 pb-5 gy-4">
            <Col md={6}>
              <Fade bottom>
                <div className="h-100 d-flex flex-column justify-content-center">
                  <div className="page-common-txt1 Blue_color Bold_font">
                    Experience a Spectrum of Services:
                  </div>
                  <div className="section1_text1 Blue_color Bold_font">
                    Comprehensive, <br className="d-none d-md-block" />
                    Customized, and <br className="d-none d-md-block" />
                    Centered on You
                  </div>
                </div>
              </Fade>
            </Col>
            <Col md={6}>
              <Fade bottom>
                <img className="w-100" src={img1} alt="" />
              </Fade>
            </Col>
          </Row>
          <Row className="g-3 g-md-3">
            {cards.map((item: any) => (
              <Col md={6} lg={3}>
                <Fade bottom>
                  <div className="services_card d-flex flex-column h-100">
                    <img src={item?.image} alt="image1" className="w-100" />

                    <h5 className="Secondary_color Bold_font mt-3 mx-3">
                      {item?.title}
                    </h5>
                    <div className="service_text2 Secondary_color Regular_font mt-2 mx-3">
                      {item?.description}
                    </div>
                    <Link
                      className="text-decoration-none mt-auto mx-3 mb-3"
                      to="/contact-us"
                    >
                      <div className="d-flex align-items-center gap-3 justify-content-end">
                        <div className="services_btn Secondary_color Light_font">
                          ENQUIRE NOW
                        </div>
                        <div className="blueArrow blueArrow_size mb-0">
                          <IoArrowForward color="white" size={16} />
                        </div>{" "}
                      </div>
                    </Link>
                  </div>
                </Fade>
              </Col>
            ))}
          </Row>
          <Row className="g-4 g-md-5 mt-2">
            {services.map((service, index) => (
              <Col xs={12} md={6} key={index}>
                <Fade bottom>
                  <div className="h-100">
                    <Card
                      className="requare_card h-100 Secondary_color"
                      hoverable
                      cover={<img alt={service.title} src={service.img} />}
                      style={{ borderRadius: "8px", overflow: "hidden" }}
                    >
                      <h4 className="SemiBold_font">{service.title}</h4>
                      <ul>
                        {service.details.map((detail, i) => (
                          <li className="Regular_font" key={i}>
                            {detail}
                          </li>
                        ))}
                      </ul>
                    </Card>
                  </div>
                </Fade>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
      <div className="services_bgImg">
        <Container className="d-flex flex-column justify-content-center h-100">
          <Fade bottom>
            <div style={{ letterSpacing: 2 }} className="text-white Light_font">
              We're Here When You Need Us
            </div>
            <h1 className="Bold_font text-white">
              The Best Service <br className="d-none d-sm-block" /> Providers
            </h1>
            <p className="text-white Regular_font">
              At Silver Dove Health Service, your beloved
              <br className="d-none d-sm-block" />
              person getting comprehensive ,compassionate,
              <br className="d-none d-sm-block" />
              and personalized home care... our clients…
            </p>
            <Button
              onClick={() => navigate("/contact-us")}
              className="common_button Regular_font "
              style={{ backgroundColor: "#60C6FF" }}
            >
              Contact Us
            </Button>
          </Fade>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
