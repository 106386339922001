import { useEffect, useState } from "react";
import Header from "../components/header";
import "./styles.scss";
import {
  Button,
  Collapse,
  CollapseProps,
  Form,
  Input,
  message,
  Upload,
} from "antd";
import { Col, Container, Row } from "react-bootstrap";
import TextArea from "antd/es/input/TextArea";
import { SlCloudUpload } from "react-icons/sl";
import Footer from "../components/footer";
import PrefixSelector from "../components";
const Fade = require("react-reveal/Fade");

const Careers = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const beforeUpload = (file: any) => {
    const isPdfOrDoc =
      file.type === "application/pdf" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

    if (!isPdfOrDoc) {
      message.error("You can only upload PDF, DOC, or DOCX files!");
      return Upload.LIST_IGNORE;
    }

    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("File must be smaller than 10MB!");
      return Upload.LIST_IGNORE;
    }

    setFileName(file.name);
    // return true;
  };
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <h6 className="Secondary_color">How many hours can I work?</h6>,
      children: (
        <p className="Secondary_color">
          How much you work is up to you. With Silver Dove, you can choose which
          placements you’d like to apply for, and work with multiple clients at
          once to create a weekly work schedule that meets your needs. This
          gives you freedom to apply your skills and experience as you see fit,
          and the flexibility to take on additional placements if you want them.
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <h6 className="Secondary_color">
          What support does Silver Dove Health Services provide?
        </h6>
      ),
      children: (
        <p className="Secondary_color">
          Our intuitive platform helps self-employed carers find work that meet
          their needs, and communicate with the families they match with. Our
          platform also makes it easier to apply for and manage work, book
          breaks, and find the right support in our help centre, or from
          dedicated team who are available by phone or through live chat. Elder
          also takes care of the tricky stuff, such as managing your payments,
          and organising cover when you take a break. We also provide ongoing
          support through the Elder Hub app and emergency helpline for when
          you’re on placement.
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <h6 className="Secondary_color">Will I need to work overnight?</h6>
      ),
      children: (
        <p className="Secondary_color">
          We don’t currently offer overnight shifts to hourly carers. If you’d
          like to work overnight, you may be interested in our live-in care
          placements.{" "}
        </p>
      ),
    },
  ];

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const api = "";
      const file = values.resume[0].originFileObj;
      console.log(file, "file");
      let mobile = `${values.code} ${values.phoneNumber}`;
      let obj = {
        name: values.firstName + values?.lastName,
        email: values?.email,
        phoneNumber: values?.phoneNumber ? mobile : "",
        applyingPost: values?.applyingPost,
        resume: file,
        message: values?.message,
      };
      let response: any = await props?.post(api, obj);
      if (response.status) {
        message.success("Success");
      } else {
        message.error("oops.something gone wrong.");
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };
  const careers = [
    {
      image: require("../assets/images/service-img1.png"),
      head: "Flexible working hours",
      description:
        "Set your preferences, choose when and where you work, and book breaks with ease.",
    },
    {
      image: require("../assets/images/salary.jpg"),
      head: "Competitive pay.",
      description:
        "Earn a competitive salary that values your skills and dedication.",
    },
    {
      image: require("../assets/images/make-difference.jpg"),
      head: "Make a real difference",
      description:
        "We’re looking for people who are passionate about making a difference in people’s lives.",
    },
    {
      image: require("../assets/images/supportaa.jpg"),
      head: "Access support",
      description:
        "Get support around-the-clock via the daily newsfeed and help centre.",
    },
  ];
  return (
    <>
      <Header />
      <Container className="common_cover">
        <Row className="g-2 g-md-4">
          <Col md={6}>
            <Fade bottom>
              <div className="h-100 d-flex flex-column justify-content-center">
                <div className="page-common-txt1 Blue_color Bold_font">
                  Make a Difference in Lives:
                </div>
                <div className="section1_text1 Blue_color Bold_font">
                  Fulfilling, Passionate <br className="d-none d-md-block" />{" "}
                  and Focused on
                  <br className="d-none d-md-block" /> In-Home Care
                </div>
              </div>
            </Fade>
          </Col>
          <Col md={6}>
            <div className="careers_image">
              <img src={require("../assets/images/careers.jpg")} alt="image1" />
            </div>
          </Col>
          <div className="my-4 my-md-5">
            <Fade bottom>
              <div className="section1_text1 Blue_color Bold_font text-center mb-2 mb-md-5">
                Why Choose Us
              </div>
            </Fade>

            <Row className="g-3">
              {careers.map((item: any) => (
                <Col md={6} lg={3}>
                  <Fade bottom>
                    <div className="services_card d-flex flex-column h-100">
                      <div className="image_div">
                        <img
                          src={item?.image}
                          alt="image1"
                          className="w-100 h-100"
                        />
                      </div>
                      <div className="p-3">
                        <h5 className="Secondary_color Bold_font">
                          {item?.head}
                        </h5>
                        <div className="service_text2 Secondary_color Regular_font mt-2">
                          {item?.description}
                        </div>
                      </div>
                    </div>
                  </Fade>
                </Col>
              ))}
            </Row>
          </div>

          <div className=" my-2 mt-md-5">
            <p className="section3_para Regular_font Secondary_color">
              Complete the form below to learn about our current opportunities
              and take the first step towards a rewarding career with us.
            </p>
          </div>

          <Col xs={12} md={7}>
            <Form onFinish={onFinish}>
              <Row>
                <Col md={6}>
                  <div className="label text-start">First Name</div>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                  <div className="label text-start">Email</div>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please input your email!" },
                    ]}
                  >
                    <Input size="large" type="email" />
                  </Form.Item>{" "}
                  <div className="label text-start">Position Applying For</div>
                  <Form.Item
                    name="applyingPost"
                    rules={[
                      {
                        required: true,
                        message: "Please enter applying position!",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <div className="label text-start">Last Name</div>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                  <div className="label text-start">Phone Number</div>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="phone_Select"
                      addonBefore={<PrefixSelector />}
                      type="text"
                      onInput={(e) => {
                        e.currentTarget.value = e.currentTarget.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                      }}
                    />
                  </Form.Item>
                  <div className="label text-start">Attach Resume</div>
                  <Form.Item
                    name="resume"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e && e.fileList}
                    rules={[
                      {
                        required: true,
                        message: "Please upload your resume!",
                      },
                    ]}
                  >
                    <Upload
                      className="uploader"
                      name="file"
                      beforeUpload={beforeUpload}
                      maxCount={1}
                      showUploadList={false}
                    >
                      <Input
                        suffix={<SlCloudUpload />}
                        size="large"
                        readOnly
                        value={fileName}
                        placeholder="PDF or DOCX, max 10MB"
                        style={{ width: "100%" }}
                      />{" "}
                    </Upload>
                  </Form.Item>{" "}
                </Col>
                <div className="label text-start">Message</div>
                <Form.Item name="message">
                  <TextArea rows={4} size="large" maxLength={300} />
                </Form.Item>
                <Col xs={12} md={{ span: 3, offset: 9 }}>
                  <Button
                    block
                    className="main_button"
                    style={{ backgroundColor: "#1A75BB" }}
                    loading={isLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md={5} xs={12}>
            <div className="text-center Blue_color my-4 my-md-2">
              <h2>Frequently Asked Questions?</h2>
              <h5 className="section3_para Regular_font Secondary_color mx-1">
                {" "}
                Care can be confusing, difficult to talk about. We’ve answered
                some common questions below.
              </h5>{" "}
            </div>
            <Collapse
              ghost
              expandIconPosition="end"
              size="large"
              items={items}
              bordered={false}
            />
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default Careers;
