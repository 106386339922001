import { useEffect } from "react";
import Header from "../components/header";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/footer";
const Resources = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Header />
      <Container className="common_cover">
        <Row>
          <Col md={6} xs={12}>
            <div
              style={{ backgroundColor: "#F6FCFF" }}
              className="p-4 my-2 Bold_font Blue_color"
            >
              <a
                href="https://alz.org"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Alzheimers Association
              </a>
            </div>
            <div
              style={{ backgroundColor: "#F6FCFF" }}
              className="p-4 my-2 Bold_font Blue_color"
            >
              <a
                href="https://redcross.org"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                American Red Cross
              </a>
            </div>
            <div
              style={{ backgroundColor: "#F6FCFF" }}
              className="p-4 my-2 Bold_font Blue_color"
            >
              <a
                href="https://www.apha.org"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                American Public Health Association
              </a>
            </div>
            <div
              style={{ backgroundColor: "#F6FCFF" }}
              className="p-4 my-2 Bold_font Blue_color"
            >
              <a
                href="https://www.familiesusa.org"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                FamiliesUSA
              </a>
            </div>
            <div
              style={{ backgroundColor: "#F6FCFF" }}
              className="p-4 my-2 Bold_font Blue_color"
            >
              <a
                href="https://www.healthfinder.gov"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Health Finder
              </a>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              style={{ backgroundColor: "#F6FCFF" }}
              className="p-4 my-2 Bold_font Blue_color"
            >
              <a
                href="https://hcaoa.org"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Home Care Association of America
              </a>
            </div>
            <div
              style={{ backgroundColor: "#F6FCFF" }}
              className="p-4 my-2 Bold_font Blue_color"
            >
              <a
                href="https://www.nahc.org"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                National Association for Home Care and Hospice
              </a>
            </div>
            <div
              style={{ backgroundColor: "#F6FCFF" }}
              className="p-4 my-2 Bold_font Blue_color"
            >
              <a
                href="https://www.healthinaging.org"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                The AGS Foundation for Health in Aging
              </a>
            </div>
            <div
              style={{ backgroundColor: "#F6FCFF" }}
              className="p-4 my-2 Bold_font Blue_color"
            >
              <a
                href="https://www.pdf.org"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Parkinson’s Disease Foundation, Inc.
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Resources;
